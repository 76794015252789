import React, { useEffect, useRef } from "react";
import {
  IonAvatar,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
} from "@ionic/react";
 
import {   addCircle, closeCircle } from "ionicons/icons";

export interface DataItemT {
  id: string;
}

interface InteractiveTagBarProps {
  items: DataItemT[] | undefined;
  activeItem: DataItemT;
  onItemClick: (event: React.MouseEvent, item: DataItemT) => void;
  onRemoveItem?: (item: DataItemT) => void;
  containerStyle?: React.CSSProperties;
  itemStyle?: React.CSSProperties;
  showGradient?: boolean;
  deleteIcon?: boolean;
  defaultActiveItem?: DataItemT;
  showAddIcon?: boolean;
  onAddItem?: () => void;
}

const InteractiveTagBar: React.FC<InteractiveTagBarProps> = ({
  items,
  activeItem,
  onItemClick,
  onRemoveItem,
  containerStyle = {},
  itemStyle = {},
  showGradient = true,
  deleteIcon = false,
  defaultActiveItem,
  showAddIcon = true,
  onAddItem,
}) => {
 

  const hasItems = (items: any[] | undefined | null) => {
    return items && items.length > 0;
  };
  const handleAddItem = (e: any) => {
    e.stopPropagation();
    onAddItem?.();
  };

  const renderAddIcon = (
    <IonIcon
      onClick={(e) => handleAddItem(e)}
      icon={addCircle}
      style={{ fontSize: "1.5rem", color: "#3880ff",marginLeft:"10px" }}
    />
  );

  const renderAddChip = (
    <IonChip color="primary" onClick={(e) => handleAddItem(e)}>
      <IonIcon icon={addCircle} color="primary" />
      <IonLabel color="primary">Add Filter</IonLabel>
    </IonChip>
  );

  return (
    <div
      style={{
        overflowX: "auto",
        whiteSpace: "nowrap",
        padding: "10px 0",
        position: "relative",
        ...containerStyle,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "nowrap",
          paddingLeft: "3px",
          paddingBottom: "5px",
          scrollbarWidth: "none",
          overflowX: "scroll",
          msOverflowStyle: "none",
          gap: "10px",
          ...containerStyle,
        }}
      > 

        <IonRow
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
            paddingLeft: "3px",
            paddingBottom: "5px",
            scrollbarWidth: "none",
            overflowX: "scroll",
            msOverflowStyle: "none",
            gap: "10px",
            ...containerStyle,
          }}
        >
          {showAddIcon && onAddItem && <IonCol>{hasItems(items) ? renderAddIcon : renderAddChip}</IonCol> }

          {items?.map((item, index) => {
            return (
              <IonCol key={item.id || index}>
                <IonChip
                  key={index}
                  data-id={item.id}
                  onClick={(e) => onItemClick(e, item)}
                  style={{
                    flexShrink: 0,
                    display: "inline-flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "5px 15px",
                    border:
                      activeItem.id === item.id
                        ? "1px solid #007bff"
                        : "0.5px solid #ccc",
                    borderRadius: "20px",
                    backgroundColor:
                      activeItem.id === item.id ? "#3880ff" : "white",
                    color: activeItem.id === item.id ? "white" : "#3880ff",
                    marginRight: "10px",
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                    fontWeight: activeItem.id === item.id ? "bold" : "normal",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    ...itemStyle,
                  }}
                >
                  <IonLabel>
                    {activeItem.id === item.id
                      ? item.id
                      : item.id.length > 8
                      ? `${item.id.slice(0, 8)}...`
                      : item.id}
                  </IonLabel>
                  {deleteIcon && onRemoveItem && (
                    <IonIcon
                      //  icon={close}
                      icon={closeCircle}
                      style={{
                        color: activeItem.id === item.id ? "white" : "#3880ff",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onRemoveItem?.(item);
                      }}
                    />
                  )}
                </IonChip>
              </IonCol>
            );
          })}
        </IonRow>
 
      </div>

      {showGradient && (
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            height: "100%",
            width: "20px",
          }}
        />
      )}
    </div>
  );
};

export default InteractiveTagBar;
