
export const soilTypes = [
    ['Feel Test Result', 'Soil Type'],   
    ['No ribbon form & feels gritty', 'Sandy'],
    ['No ribbon form & feels smooth', 'Loamy sand'],
    ['Ribbon form less than 2.5 cm & feels gritty', 'Sandy loam'],
    ['Ribbon form between less than 2.5 cm & feels smooth', 'Loam'],
    ['Ribbon form between 2.5 to 5 cm, feels gritty & smooth equally', 'Clay Loam'],
    ['Ribbon form more than 5 cm & feels gritty & smooth equally', 'Clay'],
  ];
  



export const  soilTestImages = [
      {
        name:"Ball Test",
        src: ["assets/BallTest.jpeg"],
         
      },
      {
        name:"Gritty Test",
        src:["assets/GrittyTest.jpeg"]
      },
      {
        name:"Ribben Test",
        src:["assets/RibbenTest.jpeg"]
      },
    ];
  