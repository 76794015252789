import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonRow,
    IonSearchbar,
    IonSpinner,
    IonTitle,
    IonToolbar,
  } from "@ionic/react";
//   import { useCallback, useEffect, useState } from "react";
import { useEffect, useState, useCallback, ChangeEvent } from "react";
  import { getSchemesByPagination } from "../services/schemes.services";
  import { calendarOutline, callOutline, arrowBackOutline, personOutline } from "ionicons/icons";
  import "./Schemes.css";
import CustomSearchBox from "../components/CustomSearchBox/CustomSearchBox";
  
  const Schemes: React.FC = () => {
    const [schemes, setSchemes] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [page, setPage] = useState<number>(0);
  
    const [searchText, setSearchText] = useState<string>("");
  
    const [selectedScheme, setSelectedScheme] = useState<any | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
  
    const pageSize = 10;
  
    const loadSchemes = useCallback(
        async (currentPage: number, search?: string) => {
            try {
              setIsLoading(true);
              const skip = currentPage * pageSize;
              const filters: any[] = [];
              
              if (search && search.trim() !== "") {
                const term = search.trim();
                filters.push({
                  or: [
                    { name: { regexp: `/${search.trim()}/i` } },
                    { contactPerson: { regexp: `/${search.trim()}/i` } },
                    { status: { regexp: `/${search.trim()}/i` } },
                    { description: { regexp: `/${search.trim()}/i` } },
                    { cropId: { regexp: `/${term}/i` } },
                    { deviceId: { regexp: `/${term}/i` } }
                  ]
                });
              }
              const response = await getSchemesByPagination(skip, pageSize, filters);
              const data = await response.json();
              if (!Array.isArray(data)) {
                throw new Error("Response data is not an Array");
              }
              if (data.length < pageSize) {
                setHasMore(false);
              }
              setSchemes((prevSchemes) => [...prevSchemes, ...data]);
              setPage(currentPage + 1);
            } catch (error) {
              console.error("Error Fetching schemes data", error);
            } finally {
              setIsLoading(false);
            }
          },[pageSize]
    )
      
  
    // useEffect(() => {
    //   setSchemes([]);
    //   setPage(0);
    //   setHasMore(true);
    //   loadSchemes(0, searchText);
    // }, []);
  
    useEffect(() => {
        const handler = setTimeout(() => {
          setSchemes([]);
          setPage(0);
          setHasMore(true);
          loadSchemes(0, searchText);
        }, 500);
      
        return () => {
          clearTimeout(handler);
        };
      }, [searchText, loadSchemes]);
      
  
    const loadData = async (event: CustomEvent<{ complete?: () => void }>) => {
      await loadSchemes(page, searchText);
      if (event.detail && typeof event.detail.complete === "function") {
        event.detail.complete();
      }
    };
  
    // const handleSearchChange = (event: CustomEvent) => {
    //     const value = event.detail.value;
    //     console.log('Search value:', value);
    //     setSearchText(value || '');
    // };
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchText(value);
      };
  
    const openModal = (scheme: any) => {
      setSelectedScheme(scheme);
      setShowModal(true);
    };
  
    const closeModal = () => {
      setShowModal(false);
      setSelectedScheme(null);
    };
  
    const formatDate = (dateStr: string) => {
      return dateStr ? new Date(dateStr).toLocaleDateString() : "N/A";
    };
  
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/home" />
          </IonButtons>
            <IonTitle>Schemes</IonTitle>
          </IonToolbar>
          <IonToolbar>
          <IonToolbar>
          <CustomSearchBox
            searchText={searchText}
            setSearchText={setSearchText}
            placeholder="Search schemes..."
          />
          </IonToolbar>
          </IonToolbar>
          {/* <IonToolbar>
          <IonSearchbar
            value={searchText}
            debounce={300}
            placeholder="Search schemes..."
            onIonChange={handleSearchChange}
            // clearInput={true}
          />
        </IonToolbar> */}
        </IonHeader>
  
        <IonContent style={{ minHeight: "100vh" }}>
          <IonGrid>
            <IonRow>
              {schemes.map((scheme) => (
                <IonCol size="12" size-md="6" key={scheme.id || scheme.name}>
                  <IonCard className="custom-card" button onClick={() => openModal(scheme)}>
                    <IonCardHeader>
                      <IonCardTitle style={{ fontWeight: "bold" }}>
                        {scheme.name}
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <div style={{ display: "flex", alignItems: "center", marginBottom: "4px" }}>
                        <IonIcon
                          icon={personOutline}
                          style={{ marginRight: "6px", color: "var(--ion-color-primary)" }}
                        />
                        <span style={{ fontSize: "0.9em" }}>
                          Contact: {scheme.contactPerson || "N/A"}
                        </span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <IonIcon
                          icon={calendarOutline}
                          style={{ marginRight: "6px", color: "var(--ion-color-secondary)" }}
                        />
                        <span style={{ fontSize: "0.9em" }}>
                          Valid: {formatDate(scheme.validFrom)} - {formatDate(scheme.validTo)}
                        </span>
                      </div>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
          {isLoading && schemes.length > 0 && (
            <div className="loading-overlay">
            <IonSpinner />
            </div>
        )}
          {schemes.length >= pageSize && (
            <IonInfiniteScroll
              threshold="100px"
              disabled={!hasMore || isLoading}
              onIonInfinite={(e) =>
                loadData(e as unknown as CustomEvent<{ complete: () => void }>)
              }
            >
              <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="Loading more schemes..." />
            </IonInfiniteScroll>
          )}
  
          {isLoading && schemes.length === 0 && (
            <div style={{ display: "flex", justifyContent: "center", padding: "16px" }}>
              <IonSpinner />
            </div>
          )}
  
          <IonModal isOpen={showModal} onDidDismiss={closeModal} cssClass="scheme-modal" >
            <IonHeader translucent>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonButton onClick={closeModal}>
                    <IonIcon slot="start" icon={arrowBackOutline} />
                  </IonButton>
                </IonButtons>
                <IonTitle>Scheme Details</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              {selectedScheme && (
                <IonCard >
                  <IonCardHeader>
                    <IonCardTitle>
                      {selectedScheme.name}
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    {/* <IonList lines="none"> */}
                      {/* <IonItem> */}
                        <div className="modal-field">
                          <div className="modal-field-title">Description:</div>
                          <div className="modal-field-value">
                            {selectedScheme.description || "N/A"}
                          </div>
                        </div>
                      {/* </IonItem> */}
                      {/* <IonItem> */}
                        <div className="modal-field">
                          <div className="modal-field-title">Valid From:</div>
                          <div className="modal-field-value">{formatDate(selectedScheme.validFrom)}</div>
                        </div>
                      {/* </IonItem> */}
                      {/* <IonItem> */}
                        <div className="modal-field">
                          <div className="modal-field-title">Valid To:</div>
                          <div className="modal-field-value">{formatDate(selectedScheme.validTo)}</div>
                        </div>
                      {/* </IonItem> */}
                      {/* <IonItem> */}
                        <div className="modal-field">
                          <div className="modal-field-title">Device Type:</div>
                          <div className="modal-field-value">
                            {(selectedScheme.deviceId || []).join(", ") || "N/A"}
                          </div>
                        </div>
                      {/* </IonItem> */}
                      {/* <IonItem> */}
                        <div className="modal-field">
                          <div className="modal-field-title">Crops:</div>
                          <div className="modal-field-value">
                            {(selectedScheme.cropId || []).join(", ") || "N/A"}
                          </div>
                        </div>
                      {/* </IonItem> */}
                      {/* <IonItem> */}
                        <div className="modal-field">
                          <div className="modal-field-title">Region:</div>
                          <div className="modal-field-value">
                            {[
                              selectedScheme.district,
                              selectedScheme.state,
                              selectedScheme.country,
                            ]
                              .filter(Boolean)
                              .join(", ") || "N/A"}
                          </div>
                        </div>
                      {/* </IonItem> */}
                      {/* <IonItem> */}
                        <div className="modal-field">
                          <div className="modal-field-title">Contact Person:</div>
                          <div className="modal-field-value">
                            {selectedScheme.contactPerson || "N/A"}
                          </div>
                        </div>
                      {/* </IonItem> */}
                      {/* <IonItem> */}
                        <div className="modal-field">
                          <div className="modal-field-title">Status:</div>
                          <div className="modal-field-value">
                            {selectedScheme.status === "open" ? "Open" : "Closed"}
                          </div>
                        </div>
                      {/* </IonItem> */}
                    {/* </IonList> */}
                  </IonCardContent>
                </IonCard>
              )}
            </IonContent>
          </IonModal>
        </IonContent>
      </IonPage>
    );
  };
  
  export default Schemes;
  