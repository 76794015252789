import React, { createContext, useContext, useEffect, useState } from "react";
import Loader from "../components/GlobalComponents/Loader";

interface LoaderContextType {
  isLoading: boolean;
  message: string;
  showLoader: (msg?: string) => void;
  hideLoader: () => void;
}

const LoaderContext = createContext<LoaderContextType | undefined>(undefined);

export const LoaderProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("Loading...");

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const showLoader = (msg: string = "Loading...") => {
    setMessage(msg);
    setIsLoading(true);

    if (timeoutId) {
      clearTimeout(timeoutId);   
    }

    const newTimeoutId = setTimeout(() => {
      hideLoader();  
    }, 5000);
    setTimeoutId(newTimeoutId);
  };

  const hideLoader = () => {
    setIsLoading(false);
    setMessage("");
  };

  return (
    <LoaderContext.Provider value={{ isLoading, message, showLoader, hideLoader }}>
      <Loader />
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error("useLoader must be used within a LoaderProvider");
  }
  return context;
};
