import {
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  IonSlide,
  IonSlides,
  IonText,
} from "@ionic/react";
import {
  chevronDownCircleOutline,
  chevronUpCircleOutline,
  chevronUpOutline,
} from "ionicons/icons";
import React, { useState } from "react";
import { soilTestImages, soilTypes } from "../utilities/constant";

const SoilTestComponent = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible((prevState) => !prevState);
  };

  return (
    <IonGrid>
      <IonRow
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <IonText
          onClick={toggleVisibility}
          style={{
            fontSize: "20px",
            textDecoration: "underline",
            color: "#3880ff",
            textAlign: "center",
            cursor: "pointer", 
          }}
        >
          How to identify soil types?
        </IonText>
        <IonIcon
          onClick={toggleVisibility}
          icon={isVisible ? chevronUpCircleOutline : chevronDownCircleOutline}
          color="#3880ff"
          style={{
            color: "#3880ff",
            transition: "transform 0.3s ease",
            marginLeft: "5px",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
          }}
        />
      </IonRow>

      {isVisible && (
        <>
          <IonRow>
            <IonLabel
              color="primary "
              style={{
                paddingLeft: "15px",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Procedure
            </IonLabel>
            <IonText style={{ padding: "10px 15px" }}>
              Take some moist soil in your hand and squeeze it to form a ball.
              Then, press the ball with your thumb to check if it forms a
              ribbon. For the gritty test, take the soil in your hand, add a
              little water, and use your index finger to rotate the soil in a
              circular motion to feel if it’s gritty or smooth.
            </IonText>
          </IonRow>

          <IonSlides
            pager={true}
            options={{
              initialSlide: 1,
              speed: 500,
            }}
          >
            {soilTestImages.length &&
              soilTestImages.map((item, index) => (
                <IonSlide key={index}>
                  <IonRow>
                    <img
                      src={item.src[0]}
                      alt="Soil Test"
                      style={{
                        width: "100%",
                        height: "auto",
                        padding: "15px 40px",
                        objectFit: "cover",
                      }}
                    />
                  </IonRow>
                </IonSlide>
              ))}
          </IonSlides>

          {soilTypes.map((row, index) => (
            <IonRow
              key={index}
              style={{ paddingLeft: "15px", paddingRight: "15px" }}
            >
              {row.map((cell, cellIndex) => (
                <IonCol
                  key={cellIndex}
                  size={cellIndex === 0 ? "8" : "4"}
                  style={{
                    fontSize: "14px",
                    border: "1px solid #ccc",
                    padding: "10px",
                    textAlign: "center",
                    fontWeight: index === 0 ? "bold" : "normal",
                  }}
                >
                  {cell}
                </IonCol>
              ))}
            </IonRow>
          ))}
        </>
      )}
    </IonGrid>
  );
};

export default SoilTestComponent;
