import { getData } from "./common.service";

interface QueryOptions {
  limit: number;
  skip: number;
  order: string;
  where?: any;
}

/**
 * Fetches all schemes.
 */
export async function getSchemes() {
  const url = "/schemes";
  return getData(url, {
    basePath: process.env.REACT_APP_CORE_API,
    sendTokenInHeader: true,
  });
}

/**
 * Fetches schemes using pagination.
 * @param skip - Number of records to skip.
 * @param limit - Number of records to return.
 * @param filters - Optional array of filters.
 */
export async function getSchemesByPagination(
  skip: number,
  limit: number,
  filters: any[] = []
) {
  const qs: QueryOptions = {
    limit,
    skip,
    order: "created_date DESC",
  };

  if (filters.length) {
    qs.where = { and: filters };
  }

  const url = `/schemes?filter=${encodeURIComponent(JSON.stringify(qs))}`;

  return getData(url, {
    basePath: process.env.REACT_APP_CORE_API_BASEPATH,
    sendTokenInHeader: true,
  });
}
