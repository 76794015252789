import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonModal,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  IonChip,
  IonIcon,
} from "@ionic/react";
import { addCircle, closeCircle } from "ionicons/icons";
import { LeadStages, LeadStagesLabels } from "./CreateLeadOrder";
import "./OrderFilter.css";
import { getUsersWithRolesSupport } from "../services/user.service";

interface Agent {
  farmUserId: string;
  username: string;
  mobile: string;
}

interface AssignedAgent {
  agentId: string;
  agentName: string;
}

type Props = {
  filters: Record<string, any>;
  updateFilters: (filters: Record<string, any>) => void;
  onClose: () => void;
  setAssignId: (ids: string[]) => void;
  assignId: string[];
  showFilterNameInModal?: boolean;
};

const OrderFilter: React.FC<Props> = ({
  filters,
  updateFilters,
  onClose,
  setAssignId,
  assignId,
  showFilterNameInModal,
}) => {
  const [agentsList, setAgentsList] = useState<Agent[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAgentUserModal, setShowAgentUserModal] = useState(false);
  const [assignedAgents, setAssignedAgents] = useState<AssignedAgent[]>([]);
  const [filterName, setFilterName] = useState("");
  const [error, setError] = useState(false);

  const leadStage = useRef<LeadStages[]>([]);

  useEffect(() => {
    let isMounted = true;

    getUsersWithRolesSupport().then((res) => {
      if (isMounted) {
        setAgentsList(res);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  // Initialize lead stages from filters
  useEffect(() => {
    if (!!filters && filters.hasOwnProperty("stage")) {
      leadStage.current = filters.stage as LeadStages[];
    }

    if (
      !!filters &&
      filters.hasOwnProperty("assignedTo") &&
      Array.isArray(filters?.assignedTo) &&
      agentsList.length > 0
    ) {
      const foundAgents = filters?.assignedTo
        .map((assignedId: string) => {
          const agent = agentsList.find(
            (agent) => agent.farmUserId === assignedId
          );
          if (agent) {
            return {
              agentId: agent.farmUserId,
              agentName: agent.username,
            };
          }
          return null;
        })
        .filter((agent: any) => agent !== null);
      setAssignId(filters?.assignedTo);
      setAssignedAgents(foundAgents as AssignedAgent[]);
    }
  }, [filters, agentsList]);

  useEffect(() => {
    if (assignId.length > 0 && agentsList.length > 0) {
      const selectedAgents = assignId
        .map((id) => {
          const agent = agentsList.find((item) => item.farmUserId === id);
          return agent
            ? {
                agentId: agent.farmUserId,
                agentName: agent.username,
              }
            : null;
        })
        .filter((agent): agent is AssignedAgent => agent !== null);

      setAssignedAgents(selectedAgents);
    }
  }, [agentsList, assignId]);

  const handleStageChange = (newStageIds: LeadStages[]) => {
    if (Array.isArray(newStageIds)) {
      leadStage.current = [...newStageIds];
    }
  };

  const handleAgentSelection = (agent: Agent) => {
    const isAgentSelected = assignedAgents.some(
      (assigned) => assigned.agentId === agent.farmUserId
    );

    if (!isAgentSelected) {
      const newAgent = {
        agentId: agent.farmUserId,
        agentName: agent.username,
      };

      setAssignedAgents((prev) => [...prev, newAgent]);

      setAssignId([...assignId, agent.farmUserId]);
    }
  };

  const removeAgent = (agentId: string) => {
    setAssignedAgents((prev) =>
      prev.filter((agent) => agent.agentId !== agentId)
    );
    setAssignId(assignId.filter((id) => id !== agentId));
  };

  const handleReset = () => {
    setAssignedAgents([]);
    setAssignId([]);
    leadStage.current = [];
    updateFilters({});
    onClose();
  };

  const submitFilters = () => {
    if (showFilterNameInModal && !filterName.trim()) {
      setError(true);
      return;
    }

    const filters: Record<string, any> = {
      assignedIds: assignId,
    };

    if (showFilterNameInModal) {
      filters.filterName = filterName;
    }

    if (leadStage.current.length > 0) {
      filters.stage = leadStage.current;
    }

    updateFilters(filters);
    onClose();
  };

  const clearError = useCallback(() => {
    setError(false);
  }, []);

  const handleInputChange = (e: any) => {
    setFilterName(e.detail.value!);
    if (!!error) {
      clearError();
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Filter</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          {showFilterNameInModal && (<>
            <IonItem lines="none">
              <IonLabel position="stacked" style={{ fontWeight: 'bold' }}>
                Filter Name
              </IonLabel>
              <IonInput
                placeholder="Enter filter name"
                clearInput
                value={filterName}
                onIonChange={handleInputChange}
                style={{
                  marginTop:"30px",
                  padding: "5px 10px",
                  borderRadius: "8px",
                  backgroundColor: "transparent",
                  border: "1px solid #dcdcdc",
                }}
                onFocus={(e) => {
                  e.target.style.borderColor = "#3880ff";  
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = "#dcdcdc"; 
                }}
              />
              {error && (
                  <span
                    style={{
                      color: "red",
                      fontSize: "14px",
                      
                    }}
                  >
                    *Proper filter name required
                  </span>
                )}
            </IonItem>
            <IonItem lines="none"></IonItem>
          </>
            
          )}

          <IonItem>
            <IonLabel position="stacked">Stages</IonLabel>
            <IonSelect
              id="stages"
              name="stages"
              compareWith={compareWith}
              multiple={true}
              value={leadStage.current}
              placeholder="Select Stages"
              onIonChange={(e) => handleStageChange(e.detail.value)}
            >
              {Object.entries(LeadStagesLabels).map(([key, value]) => (
                <IonSelectOption key={key} value={key}>
                  {value}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>

          <IonItem
            onClick={() => setShowAgentUserModal(true)}
            lines="none"
            style={{}}
          >
            <IonLabel position="stacked">Assigned To</IonLabel>
            <IonIcon icon={addCircle} slot="end" />
          </IonItem>

          <IonItem>
            <IonGrid>
              <IonRow>
                {assignedAgents.length > 0 ? (
                  assignedAgents.map((agent) => (
                    <IonCol
                      key={agent.agentId}
                      // size="auto"
                      sizeSm="6"
                      sizeMd="4"
                      sizeLg="3"
                    >
                      <IonChip outline color="primary">
                        <IonLabel>{agent.agentName}</IonLabel>
                        <IonIcon
                          icon={closeCircle}
                          onClick={(e) => {
                            e.stopPropagation();
                            removeAgent(agent.agentId);
                          }}
                          style={{
                            fontSize: "16px",
                          }}
                        />
                      </IonChip>
                    </IonCol>
                  ))
                ) : (
                  <IonCol className="">
                    <IonLabel class="secondary-text">
                      No agents selected
                    </IonLabel>
                  </IonCol>
                )}
              </IonRow>
            </IonGrid>
          </IonItem>
        </IonList>

        <IonModal isOpen={showAgentUserModal}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowAgentUserModal(false)}>
                  Close
                </IonButton>
              </IonButtons>
              <IonTitle>Select Agents</IonTitle>
            </IonToolbar>
            <IonToolbar>
              <IonSearchbar
                placeholder="Search by name or mobile..."
                value={searchTerm}
                onIonChange={(e) => setSearchTerm(e.detail.value!)}
              />
            </IonToolbar>
          </IonHeader>

          <IonContent>
            {agentsList
              ?.filter((agent: Agent) =>
                searchTerm
                  ? `${agent.username} ${agent.mobile}`
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  : true
              )
              .map((agent: Agent) => (
                <IonCard
                  key={agent.farmUserId}
                  onClick={() => {
                    handleAgentSelection(agent);
                    setShowAgentUserModal(false);
                  }}
                >
                  <IonItem lines="none">
                    <IonLabel>
                      <h2>Name: {agent.username}</h2>
                      <p>Mobile: {agent.mobile || "No Mobile Number"}</p>
                    </IonLabel>
                  </IonItem>
                </IonCard>
              ))}
          </IonContent>
        </IonModal>
      </IonContent>

      <IonToolbar>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                fill="outline"
                color="danger"
                onClick={handleReset}
              >
                Reset
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton expand="block" color="success" onClick={submitFilters}>
                Submit
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </IonPage>
  );
};

export default OrderFilter;

const compareWith = (o1: any, o2: any) => {
  if (Array.isArray(o2)) {
    return o2.indexOf(o1) !== -1;
  }
  return o1 === o2;
};
