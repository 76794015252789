import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import IndexProvider from "./contexts/IndexContext";
import NetworkStatus from "./components/GlobalComponents/NetworkStatus";

const Index = () => {
  return (
    <React.StrictMode>
      <RecoilRoot>
        <React.Suspense fallback={<div>Loading...</div>}>
        <IndexProvider>
          <App/> 
          <NetworkStatus/>  
          </IndexProvider>
        </React.Suspense>
      </RecoilRoot>
    </React.StrictMode>
  );
};

ReactDOM.render(<Index/>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
    const confirmReload: boolean = window.confirm(
      "New update available. Reload now?"
    );
    if (confirmReload) {
      window.location.reload();
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);
