import React, { useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';

interface QRScannerComponentProps {
  onResult: (decodedText: string) => void;
  onError?: (error: string) => void;
}

const QRScannerComponent: React.FC<QRScannerComponentProps> = ({ onResult, onError }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const scannerRef = useRef<QrScanner | null>(null);
  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const [isScanning, setIsScanning] = useState<boolean>(true);
  const isMounted = useRef(true);

  // Cleanup function to properly handle unmounting
  const cleanup = () => {
    try {
      if (scannerRef.current) {
        scannerRef.current.stop();
        scannerRef.current.destroy();
        scannerRef.current = null;
      }
    } catch (error) {
      console.error('Cleanup error:', error);
    }
  };

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (!navigator.mediaDevices?.getUserMedia) {
      onError?.('Camera access is not supported by this browser');
      return;
    }

    let isInitialized = false;

    const setupScanner = async () => {
      if (!videoRef.current || isInitialized) return;

      try {
        // clean state before initializing
        cleanup();

        const stream = await navigator.mediaDevices.getUserMedia({ 
          video: { facingMode: 'environment' } 
        });
        
        if (!isMounted.current) {
          stream.getTracks().forEach(track => track.stop());
          return;
        }

        setHasPermission(true);
        isInitialized = true;

        scannerRef.current = new QrScanner(
          videoRef.current,
          (result) => {
            if (result && isMounted.current) {
              try {
                // video tracks stops first
                stream.getTracks().forEach(track => track.stop());
                
                // scanner bhi stop hoga
                if (scannerRef.current) {
                  scannerRef.current.stop();
                  setIsScanning(false);
                }
                
                onResult(result.data);
              } catch (error) {
                console.error('Error in QR result handling:', error);
              }
            }
          },
          {
            preferredCamera: 'environment',
            maxScansPerSecond: 5,
            highlightScanRegion: true,
            highlightCodeOutline: true,
            returnDetailedScanResult: true
          }
        );

        if (isScanning && isMounted.current) {
          await scannerRef.current.start();
        }

      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Failed to initialize camera';
        if (isMounted.current) {
          onError?.(errorMessage);
          console.error('QR Scanner Error:', error);
        }
      }
    };

    if (isScanning) {
      setupScanner();
    }

    return cleanup;
  }, [onResult, onError, isScanning]);

  const restartScanning = async () => {
    try {
      cleanup();
      setIsScanning(true);
    } catch (error) {
      console.error('Error restarting scanner:', error);
      onError?.('Failed to restart scanner');
    }
  };

  return (
    <div style={{ width: '100%', height: '300px', position: 'relative' }}>
      {!hasPermission && (
        <div style={{ 
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f3f4f6'
        }}>
          <p>Please allow camera access to scan QR codes</p>
        </div>
      )}
      {!isScanning && (
        <div style={{ 
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f3f4f6',
          color: 'black'
        }}>
          <p>QR Code scanned successfully!</p>
          <button 
            onClick={restartScanning}
            style={{
              marginTop: '10px',
              padding: '8px 16px',
              backgroundColor: '#007AFF',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Scan Again
          </button>
        </div>
      )}
      <video
        ref={videoRef}
        style={{ 
          width: '100%', 
          height: '100%', 
          objectFit: 'cover',
          display: isScanning ? 'block' : 'none'
        }}
      />
    </div>
  );
};

export default QRScannerComponent;