import React, { useEffect, useState } from "react";
import { Network } from "@capacitor/network";
import { FaWifi } from "react-icons/fa";
import { MdSignalWifiConnectedNoInternet0 } from "react-icons/md";

const NetworkStatus = ({ }: any) => {
  const [connected, setConnected] = useState(true);
  const [show, setShow] = useState(false);

  const statusConfig = connected
    ? {
        color: "green",
        icon: <FaWifi />,
        message: "Connection found",
      }
    : {
        color: "red",
        icon: <MdSignalWifiConnectedNoInternet0 />,
        message: "No connection",
      };

  useEffect(() => {
    const checkInitialNetworkStatus = async () => {
      const status = await Network.getStatus();
      if (!status.connected) {
        setShow(true);
        // onNetworkChange(true);
        setConnected(status.connected);
      }
    };
    checkInitialNetworkStatus();

    const listener = Network.addListener("networkStatusChange", (status) => {
      if (!connected && status.connected) {
        setShow(true);
        setTimeout(() => {
          setShow(false);
          // onNetworkChange(false);
        }, 2000);
      } else if (connected && !status.connected) {
        setShow(true);
      }
      setConnected(status.connected);
    });

    return () => {
      listener.remove();
    };
  }, [connected]);

  const messageStyles: React.CSSProperties = {
    height: "2vh",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: statusConfig.color,
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    transform: show ? "translateY(0)" : "translateY(100%)",
    transition: "transform 0.3s ease, opacity 0.3s ease",
    opacity: show ? 1 : 0,
  };

  return (
    <>
      {show && (
        <>
          <div style={messageStyles}>
              <div style={{ marginRight: "10px", fontSize: "0.5rem" }}>
                {statusConfig.icon}
              </div>
              <p style={{ fontSize: "0.5rem" }}>{statusConfig.message}</p>
            </div>
        </>
      )}
    </>
  );
};

export default NetworkStatus;

