import React, { useEffect, useState } from "react";
import { useLoader } from "../../contexts/LoaderContext";
import "./Loader.css"; 
import { TbLoader2 } from "react-icons/tb";

const Loader: React.FC = () => {
  const { isLoading, message } = useLoader();
  const [showLoader, setShowLoader] = useState(isLoading);

  useEffect(() => {
    if (isLoading) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [isLoading]);  

  if (!showLoader) return null;

  return (
    <div className="loader-overlay">
      <div className="loader-container">
        <TbLoader2 className="spinner" />
        <p className="loader-message">{message || "Loading..."}</p>
      </div>
    </div>
  );
};

export default Loader;
