import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonFab,
  IonFabButton,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonModal,
} from "@ionic/react";
import { add, chevronDownCircleOutline, funnelOutline } from "ionicons/icons";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import Orders from "../components/Orders";
import { doReload } from "../hooks/common";
import useNavigation from "../hooks/useNavigation";
import {
  API_RESPONSE_LIMIT,
  filterOrders,
  getOrders,
} from "../services/order.service";
// debounce hook
import useDebounce from "../hooks/useDebounce";
import CustomSearchBox from "../components/CustomSearchBox/CustomSearchBox";
import { useRecoilValue } from "recoil";
import {
  userProfileState,
  zammadProfileState,
} from "../services/state.service";
import { useLocation } from "react-router";
import { RxCross2 } from "react-icons/rx";
import OrderFilter from "../components/OrderFilter";
import { useGenericLocalDataStore } from "../store/useGenericLocalDataStore";
import InteractiveTagBar, {
  DataItemT,
} from "../components/GlobalComponents/InteractiveTagBar";
import { useLoader } from "../contexts/LoaderContext";
import { LeadStages } from "../components/CreateLeadOrder";

const OrderList: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const [disableInfiniteScroll, setDisableInfiniteScroll] =
    useState<boolean>(false);
  const userProfile: any = useRecoilValue(userProfileState);
  const navigateTo = useNavigation();
  const debounceSearchText = useDebounce(searchText, 1000);
  const [orders, setOrders] = useState([] as any[]);

  // filters
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filter, setFilter] = useState<any>({
    assignedTo: userProfile?.farmUserId,
  });
  const zammadProfile: any = useRecoilValue(zammadProfileState);

  const { showLoader, hideLoader } = useLoader();
  const [loading, setLoading] = useState(false);

  const { setItem, rehydrateStore, getItem } =
    useGenericLocalDataStore.getState();
  const { data, isDataLoaded } = useGenericLocalDataStore((state) => state);

  const [savedFilters, setSavedFilters] = useState<any[]>([]);
  const [processedDatas, setProcessedData] = useState();
  const [activeFilt, setActiveFilt] = useState<DataItemT>({ id: "" });
  const [assignIdForModal, setAssignIdForModal] = useState<any[]>([]);


  /**
   * API Calls
   * */


  async function fetchNext($event: CustomEvent<void>) {
    const toSkip = orders.length; // Skip the already fetched orders
    getOrders(debounceSearchText, undefined, toSkip, filter).then((items) => {
      if (items?.length > 0) {
        setOrders([...orders, ...items]);

        // Disable infinite scroll if less than the API limit is returned
        setDisableInfiniteScroll(items.length < API_RESPONSE_LIMIT);
      } else {
        setDisableInfiniteScroll(true);
      }
      ($event.target as HTMLIonInfiniteScrollElement).complete();
    });
  }

  /**
   * State Handlers
   * */

  const rehydrateData = async () => {

    if (!isDataLoaded) {
      try {
        await rehydrateStore();
      } catch (err) {
        console.log("No saved filters", err);
      }
    }
    // If data is loaded and savedFilters exist, process them
    const data: any = await getItem("saved_filters");

    if (data?.length) {
      const savedItemsInStorage = data;

      const processedData = processData(savedItemsInStorage);
      setProcessedData(processedData);
      setSavedFilters(savedItemsInStorage);

    }  
  };

  // Ensure the fetchOrders is triggered after rehydration
  const fetchOrders = async (mounted: boolean, skip: number = 0) => {
    try {
      setLoading(true);
      setDisableInfiniteScroll(false);

      const items = await getOrders(
        debounceSearchText,
        undefined,
        skip,
        filter
      );

      if (mounted) {
        setOrders(items);  
      }
    } catch (error) {
      console.error("Failed to fetch orders:", error);
    } finally {
      setLoading(false);
    }
  };
 
  useEffect(() => {
    let mounted = true;

    async function rehy() {
      await rehydrateData();
    }

    if (!isDataLoaded) {
      rehy();
    }
    if(data){setSavedFilters(data["saved_filters"])}
    fetchOrders(mounted);
    return () => {
      mounted = false;  
    };
  }, [debounceSearchText, filter]);  


  const processData = (data: any) => {
    const res = data?.map(function (item: any) {
      return {
        id: item.filterName,
        title: item.assignedTo,
      };
    });
    return res;
  };
 
  const handleFilterForModal = (filter: any) => {
    const { assignedIds, filterName, stage } = filter;

    //:: case1:  Save Filter
    const filtersToSave = Array.isArray(savedFilters) ? savedFilters : [];
    if (filterName) {
      const dataGoingToBeSaved = {
        filterName: filterName,
        assignedTo: assignedIds,
        stage: stage,
      };
      setFilter({ assignedTo: assignedIds, stage: stage }); 
      setItem("saved_filters", [dataGoingToBeSaved, ...filtersToSave]);
      setActiveFilt({id:filterName})

      return;
    }
    //:: case2 : Reset
    if (Object.keys(filter).length === 0) {
      setFilter({ assignedTo: userProfile?.farmUserId });
      setActiveFilt({ id: "" });
      return;
    }
    //:: case3 : filter is applied
    setActiveFilt({ id: "" });
    setFilter({ assignedTo: assignedIds, stage: stage });
    return;

  };

  // whenever data changes these will run automatically to re-fetch the new data from store.
  useEffect(()=>{
    const newData=data["saved_filters"]
    const processD=processData(newData)
    setProcessedData(processD)
    setSavedFilters(newData)

  },[data])

  useEffect(() => {
    
    if (loading) {
      showLoader();
    }  
    return () => {
      hideLoader();
    };
  }, [loading]);

  const openFilterModal = () => setShowFilterModal(true);
  const [showFilterNameInModal, setShowFilterNameInModal] =
    useState<boolean>(false);

  const closeFilterModal = () => {
    if (showFilterNameInModal) {
      setShowFilterNameInModal(false);
    }
    setShowFilterModal(false);
  };

  async function handleRemoveItem(item: DataItemT) {
    const updatedSavedFilters = savedFilters.filter(
      (it) => it.filterName !== item.id
    );
    setItem("saved_filters", updatedSavedFilters);
    setFilter({
      assignedTo: userProfile?.farmUserId,
    });
    setActiveFilt({ id: "" })
  }

  function handleItemClick(e: React.MouseEvent, item: any) {
    if( item.id=== activeFilt.id){return;}
    const itemClicked = savedFilters.filter((it) => it.filterName === item.id);
    const { filterName, stage, assignedTo } = itemClicked[0];
    setFilter({ assignedTo: assignedTo, stage: stage });
    setActiveFilt({ id: filterName });
  }

  function handleAddIcon() {
    setShowFilterNameInModal(true);
    openFilterModal();
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Orders</IonTitle>
        </IonToolbar>
        <IonItem lines="none">
          <CustomSearchBox setSearchText={setSearchText} />
          <IonIcon
            icon={funnelOutline}
            slot="end"
            onClick={openFilterModal}
            size="small"
          ></IonIcon>
        </IonItem>
      </IonHeader>
      <IonContent>
        <InteractiveTagBar
          items={processedDatas}
          activeItem={activeFilt}
          onItemClick={handleItemClick}
          onRemoveItem={handleRemoveItem}
          deleteIcon={true}
          showAddIcon={true}
          onAddItem={handleAddIcon}
        />

        <IonRefresher slot="fixed" onIonRefresh={doReload}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing..."
          ></IonRefresherContent>
        </IonRefresher>
        <Orders orders={orders} onOrderUpdate={() => fetchOrders(true, 0)} />
        <IonInfiniteScroll
          threshold="100px"
          disabled={disableInfiniteScroll}
          onIonInfinite={(e: CustomEvent<void>) => fetchNext(e)}
        >
          <IonInfiniteScrollContent loadingText="Loading more..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton>
            <IonIcon
              icon={add}
              onClick={() => navigateTo("/tabs/order", { orderId: null })}
            />
          </IonFabButton>
        </IonFab>
      </IonContent>

      <IonModal isOpen={showFilterModal} cssClass="my-custom-class">
        <OrderFilter
          filters={filter ?? {}}
          onClose={closeFilterModal}
          updateFilters={handleFilterForModal}
          setAssignId={setAssignIdForModal}
          assignId={assignIdForModal}
          showFilterNameInModal={showFilterNameInModal}
        />
      </IonModal>
    </IonPage>
  );
};

export default OrderList;
