import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonCol,
  IonButton,
  IonFooter,
} from "@ionic/react";
import "./Profile.css";
import useStore from "../store/useStore";
import { useEffect, useState } from "react";

interface ComponentProps {
  userProfile: any
}

const Profile: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { clearCache } = useStore.getState();
  const [loading,setLoading]=useState(false);

  const handleClearCache=()=>{
      setLoading(true);
      clearCache()
      setTimeout(()=>{
          setLoading(false);
      },1500)
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>User Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">User Profile</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">User Name</IonLabel>
            <IonInput
              readonly
              id="username"
              name="username"
              value={props.userProfile.username}
            ></IonInput>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Name</IonLabel>
            <IonInput
              readonly
              id="name"
              name="name"
              value={props.userProfile.name}
            ></IonInput>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Email</IonLabel>
            <IonInput
              readonly
              id="email"
              name="email"
              value={props.userProfile.email}
            ></IonInput>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Mobile</IonLabel>
            <IonInput
              readonly
              id="mobile"
              name="mobile"
              value={props.userProfile.mobile}
            ></IonInput>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Gender</IonLabel>
            <IonInput
              readonly
              id="gender"
              name="gender"
              value={props.userProfile.gender}
            ></IonInput>
          </IonItem>
        </IonList>
        <IonFooter style={{marginTop:"100px"}}>
            <IonCol className="ion-text-center" size="10">
              <IonButton onClick={handleClearCache} expand="block" disabled={loading}>
                {loading ? "Clearing...":"Clear Cache"}
              </IonButton>
            </IonCol>
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
