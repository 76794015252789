import { create } from "zustand";
import { set as idbSet, get as idbGet, del as idbDel, clear as idbClear } from "idb-keyval";
import { GenericLocalDataStoreT } from "./types.store";

const STORE_KEY = "generic-data-store";
 
export const useGenericLocalDataStore = create<GenericLocalDataStoreT>((set, get) => {

  // Initialize the store by loading persisted data from IndexedDB (rehydration)
  (async () => {
    const persistedData = await idbGet(STORE_KEY);
    if (persistedData) {
      set({ data: persistedData });
    }
  })();

  return {
    data: {},  
    isDataLoaded: false,


    getItem: async <T>(key: string) => {
      const item = get().data[key]
      return item;  
    },

    setItem: async <T>(key: string, value: T) => {
      const currentData = get().data;
      const updatedData = { ...currentData, [key]: value };
      await idbSet(STORE_KEY, updatedData);
      set({ data: updatedData });
    },

    deleteItem: async (key: string) => {
      const currentData = get().data;
      const { [key]: removed, ...updatedData } = currentData;
      await idbSet(STORE_KEY, updatedData);
      set({ data: updatedData });
    },

    getAllItems: async <T>(key: string) => {
        const currentData = get().data;
        return currentData[key] || []; 
      },
      
      

    clearStore: async () => {
      await idbClear();  
      set({ data: {} });  
    },

    // Rehydrate the store with persisted data
    rehydrateStore: async () => {
      
       try {
        const persistedData = await idbGet(STORE_KEY);
           if (persistedData) {

          set({ data: persistedData, isDataLoaded: true });
          return true;
           }
        set({ isDataLoaded: true });
           return false;
        } catch (error) {
   console.error("Error rehydrating store:", error);
   set({ isDataLoaded: true });
   return false;
        }
      }
    }
});
